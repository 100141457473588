import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import moment from "moment";
import ReactMarkdown from 'react-markdown'
import remarkGfm from "remark-gfm";
import rehypeRaw from 'rehype-raw';
import i18n from '../../i18n';
import ImageD from '../../assets/svg/Banner-image.svg';
import Banner from '../common/banner/banner';
import ReadingTimeCalculator from '../../utils/read-time-calculator';
import '../article-view/article-view.css';

function ArticleView() {
  const { t } = useTranslation();

  const [categories, setCategories] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const [articleDetails, setArticleDetails] = useState([]);
  const [popularArticle, setPopularArticle] = useState([]);

  const params = useParams();
  // const [currentPage, setCurrentPage] = useState(parseInt(params.id));
  const [image, setImage] = useState('');
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getArticleById();
    getPopularArticle();
    getCategoryList();
  }, [params.id])

  useMemo(() => getCategory(categories), [categories]);

  function getArticleById() {
    axios.get(process.env.REACT_APP_BASE_URL + 'articles/' + params.id + '?populate=*').then(
      (response) => {
        // Extract article attributes data from the response
        const data = response?.data?.data?.attributes;
        setArticleDetails(data); // Set article details

        // Extract image URL
        const imageUrl = data?.image?.data?.attributes?.url;

        // Call image details method
        setImageBasedOnUrl(imageUrl);

      }).catch((error) => {
        // Handle errors: close a modal or display an error message
        setOpen(false);
        console.log('API call error:', error);
      });
  }

  function getPopularArticle() {
    // setPopularArticle([])
    setOpen(true);
    axios.get(process.env.REACT_APP_BASE_URL + 'filtered/popular-article').then(
      (response) => {
        // Assuming response.data.data is an array of popular articles
        const popularArticles = response?.data?.data || [];
        // Limit the popular articles to the first 3 items
        setPopularArticle(popularArticles.slice(0, 3));
        setOpen(false);
      },
      (error) => {
        setOpen(false);
        console.log(error)
      }
    );
  }

  function viewDetail(e, id) {
    e.preventDefault();
    navigate("/view/" + id);
  }

  function getCategoryList() {
    axios.get(process.env.REACT_APP_BASE_URL + 'filtered/category/list').then(
      (response) => {
        setCategories(response.data.data);
      },
      (error) => {
        console.log('API call error:', error);
      }
    );
  }

  function getCategory() {
    setCategoryList(categories);
  }

  function articleSearch(e, row) {
    e.preventDefault();
    navigate("/category/" + row.id);
  }

  // Method to set 'image' based on 'imageUrl'
  function setImageBasedOnUrl(imageUrl) {
    try {
      if (imageUrl !== undefined && imageUrl !== null) {
        setImage(imageUrl)
      } else {
        setImage(null)
        // setImage(ImageD)
      }
    } catch (error) {
      console.error('Error while setting image:', error);
    }
  }

  return (
    <>
      <div className="container mb-3 my-4">

        <section id="Hotjobs">

          <div className="mt-2">
            <div className="row mb-2 justify-content-between">

              {/* Article details area start */}

              <div className="col-md-9 custom-col9">
                <div className="row mb-4 custom-px">
                  <div className="card job-list-card p-sm-2">
                    <div className="card-body blog-body ">
                      <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12 px-4">
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb bread-link">
                              <li className="breadcrumb-item">
                                <Link to={"/"}>
                                  {t('breadcurmb')}
                                </Link>
                              </li>
                              {/* <li className="breadcrumb-item">
                                {t('article_detail')}
                              </li> */}
                              <li
                                className="breadcrumb-item active card-menu "
                                aria-current="page">
                                {articleDetails?.title}
                              </li>
                            </ol>
                          </nav>
                        </div>
                        {/* title */}
                        <div className="col-md-12 px-4  ">
                          <h5 className="my-3 font-30">
                            {articleDetails?.title}
                          </h5>
                        </div>

                        <div className="d-flex justify-content-between px-4 py-2 d-block-sm">
                          <div className="col-md-6 mb-2">
                            <div className="d-grid gap-2 d-flex ">
                              <div>
                                {/* <i className="fa-sharp fa-solid fa-rotate fa-flip-vertical date-time-icon"></i> */}
                                <i className="fas fa-sync-alt date-card-icon"></i>
                                <span className="date-time ms-1">
                                  {articleDetails.createdAt !== undefined && articleDetails.createdAt !== null ?
                                    (<>{moment.utc(articleDetails.createdAt).format("DD-MM-YYYY")}</>) : (<></>)}
                                </span>
                              </div>
                              {articleDetails?.content && (
                                <div>
                                  <i className="far fa-clock date-card-icon"></i>
                                  <span className="date-time ms-1">
                                    <ReadingTimeCalculator bodyContent={articleDetails?.content} />
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* image */}
                        <div className="relative overflow-hidden px-4">
                          <div className="img-div w-100">
                            {image !== null ? (<img src={image} alt="" />) : (<></>)}
                          </div>
                        </div>
                        {/* content */}
                        <div className="col-md-12 px-4">
                          <ReactMarkdown
                            children={articleDetails.content}
                            className="react-markdown-detail"
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeRaw]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6 custom-px text-center ">
                    <div className="bootom-right-bg ">

                      <div>
                        <h5 className="inquiry-hd">
                          Inquiry about SIM for IoT/M2M
                        </h5>
                        <p className="inquiry-p">
                          If you are interested in SIM for IoT/M2M, <br />
                          please contact us.
                        </p>
                        <button className="inquiry-btn">
                          To inquiry
                        </button>
                      </div>

                    </div>
                  </div>
                  <div className="col-md-6 custom-px">
                    <div className="bottom-banner">
                      {/* bottom banner */}
                      <Banner className="img-fluid" />
                    </div>
                  </div>
                </div>

              </div>
              {/* blog details area end */}

              {/* side filter start */}
              <div className="col-md-3 custom-col3 ">

                <div className="row">
                  <div className="relative overflow-hidden ">
                    <div className="right-banner w-100">
                      {/* side banner */}
                      <Banner />
                    </div>
                  </div>
                </div>

                {/* popular article area starts */}
                <div className="row my-3">
                  {popularArticle?.length !== 0 ?
                    <div className="mb-3">
                      <h5>{t('popular_aticle')}</h5>
                      <div className="border-bottom-article"></div>
                    </div> : <></>}

                  {popularArticle.map((row, index) => {
                    return (
                      <>
                        <div className="mb-3 col-sm3 ">
                          <div className="card right-white p-3">
                            {/* popular article image */}
                            <div className="right-white-img">
                              <img src={row?.article?.image?.url !== undefined && row?.article?.image?.url !== null ?
                                row?.article?.image?.url : ImageD} alt=""
                                className="card-img-top" />
                            </div>
                            <div className="card-body p-0 pt-3">
                              {/* popular article title */}
                              <h5 className="right-heading">
                                <a
                                  onClick={e => viewDetail(e, row?.article?.id)}
                                  style={{ cursor: 'pointer' }} className="link-hover"
                                >
                                  {row?.article?.title}
                                </a>
                              </h5>

                              <div className="card-text mb-0 right-heading">
                                {/* popular article content */}
                                <ReactMarkdown
                                  children={row?.article?.content}
                                  className="react-markdown-detail"
                                  remarkPlugins={[remarkGfm]}
                                  rehypePlugins={[rehypeRaw]}
                                />
                              </div>

                              <div className="d-grid gap-2 d-flex mt-2">
                                <div>
                                  <i className="fas fa-sync-alt date-time-icon"></i>
                                  {/* popular article date */}
                                  <span className="date-time-card ms-1">
                                    {row?.article?.createdAt !== undefined && row?.article?.createdAt !== null ?
                                      (<>{moment.utc(row?.article?.createdAt).format("DD-MM-YYYY")}</>) : (<></>)}
                                  </span>
                                </div>
                                <div>
                                  <i className="far fa-clock date-time-icon"></i>
                                  {/* popular article time */}
                                  <span className="date-time-card ms-1">
                                    {row?.article?.createdAt !== undefined && row?.article?.createdAt !== null ?
                                      (<>{moment.utc(row?.article?.createdAt).format("hh:mm a")}</>) : (<></>)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>)
                  })}
                </div>
                {/* popular article area ends */}

                {/* category area starts */}
                <div className="row my-3">

                  <div className="mb-3">
                    <h5 className="blog_list_heading">{t('category')}</h5>
                    <div className="border-bottom-article"></div>
                  </div>

                  <ul
                    className="list-group list-group-flush px-4">
                    {categoryList.map((row, index) => (
                      <>
                        <li
                          onClick={e => articleSearch(e, row)}
                          className="list-group-item py-3 list-custom d-flex w-100 justify-content-between">
                          <span>{row?.title}</span>
                          <span>
                            <i className="fa-solid fa-angle-right"></i>
                          </span>
                        </li>
                      </>
                    ))}
                  </ul>

                </div>
                {/* category area ends */}

              </div>
            </div>
            {/* side blog list end */}

          </div>
        </section >
      </div >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default ArticleView;
