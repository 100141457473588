import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import '../footer/footer.css';

// Footer component that accepts 'footer' and 't' as props
function Footer() {
  const { t } = useTranslation();
  const [footer, setFooter] = useState({});
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    getFooter();
  }, [])
  function getFooter() {
    axios.get(process.env.REACT_APP_BASE_URL + 'footer?populate[0]=companyLogo').then(
      (response) => {
        setFooter(response?.data?.data);
        console.log(footer, "foo")
      },
      (error) => {
        console.log(error)
      }
    );
  }

  return (
    <footer className="footer mt-auto">
      <div className="footer-bg py-3">
        <div className="container-fluid padding-body">
          <div className="row py-3">

            <div className="col-md-2">
              {/* Render navigation links for job search, service introduction, and company profile */}
              <nav className="nav flex-column">
                <a className="nav-link nav-footer" target="_blank" href={footer?.attributes?.jobSearch}>{t('job_search')}</a>
                <a className="nav-link nav-footer" target="_blank" href={footer?.attributes?.serviceIntroduction}>{t('about_service')} </a>
                <a className="nav-link nav-footer" target="_blank" href={footer?.attributes?.companyProfile}>{t('company_profile_footer')}</a>
              </nav>
            </div>

            <div className="border-left col-md-1 "></div>
            <div className="border-bottom1 col-md-1 "></div>

            <div className="col-md-6">
              {/* Render navigation links for inquiries, privacy policy, terms of use, and occupation handling */}
              <nav className="nav flex-column">
                <a className="nav-link nav-footer" target="_blank" href={footer?.attributes?.inquiry}>{t('inquiries')} </a>
                <a className="nav-link nav-footer" target="_blank" href={footer?.attributes?.privacyPolicy}> {t('privacy_policy')}</a>
                <a className="nav-link nav-footer" target="_blank" href={footer?.attributes?.termsAndCondition}>{t('terms_of_use')}</a>
                <a className="nav-link nav-footer" target="_blank" href={footer?.attributes?.occupationRangeClarification}>{t('occupation_handling')}</a>

              </nav>
            </div>

            <div className="col-md-3 justify-content-center ms-auto flex-column d-flex footer-pa-logo">
              {/* Render the company logo and license details */}
              <div className='footer-logo m-auto'>
                <img src={footer?.attributes?.companyLogo?.data?.attributes?.url} alt="Company Logo"></img>
              </div>
              <p className='footer-colr-plogo m-auto pt-3 footer-plogo'>{footer?.attributes?.licenceDetails}</p>
            </div>

          </div>
        </div>
      </div>

      <div className="footer-bottom mt-auto">
        {/* Render the copyright information */}
        <div className="col-md-12 text-center">
          <span className="copy-right">
            <i className="fa-regular fa-copyright"></i>{footer?.attributes?.copyRight}
          </span>
        </div>
      </div>

    </footer>
  )

}

export default Footer;
