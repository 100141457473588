import React from 'react';
import LPNavImage from '../../assets/svg/lp-nav.svg';
import LPNav from '../LPNav/LPNav';
import '../big-banner/big-banner.css';

const BigBanner = () => {

    return (
        <>
            <section className="d-flex flex-column align-items-center bg-black text-white custom-line-height">
                <div className="d-flex flex-column flex-xl-row justify-content-center align-items-center gap-4 w-100 ">
                    <div className="lpcv-container d-flex flex-column justify-content-center flex-xl-row justify-content-xl-between align-items-center">
                        <img
                            src={LPNavImage}
                            alt="iotネットワークとSIM"
                            width="500"
                            height="500"
                            loading="lazy"
                            className="img-fluid"
                        />
                        <div className="d-flex flex-column justify-content-end align-items-end text-white px-4 text-end gap-4">
                            <p className="text-white fw-bold text-3xl">
                                1枚からすぐに始められるIoT/M2M向け法人SIM</p>
                            <div className='text-end middle-text'>
                                <p className="text-white">通信量・対応エリア・運用費を</p>
                                <p className="text-white">利用シーンに合わせてカスタマイズ</p>
                            </div>
                            <ul className="text-end text-white fs-5">
                                <li >１枚から利用可能</li>
                                <li >契約期間縛りなし</li>
                                <li >最短即日発送</li>
                            </ul>
                        </div>

                    </div>
                    <div className="lpcv-sidebar d-flex justify-content-center align-items-center">
                        <LPNav />
                    </div>
                </div>
            </section>
        </>
    );
};

export default BigBanner;
