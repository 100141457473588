import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const ReadingTimeCalculator = ({ bodyContent }) => {
    const [readingTime, setReadingTime] = useState(null);

    useEffect(() => {
        if (bodyContent) {
            const doc = new DOMParser().parseFromString(bodyContent, 'text/html');
            const textContent = doc.body.textContent || '';
            const wordCount = textContent.split(/\s+/).length;
            const wordsPerMinute = 200; // Adjust this value based on your desired reading speed

            const readingTimeMinutes = Math.ceil(wordCount / wordsPerMinute);
            setReadingTime(readingTimeMinutes);
        } else {
            // Handle the case when bodyContent is null or empty
            setReadingTime(null);
        }
    }, [bodyContent]);

    return (
        <>
            {readingTime !== null ? (
                <>
                    {readingTime} min
                </>
            ) : (
                <></>
            )}
        </>
    );
};

ReadingTimeCalculator.propTypes = {
    bodyContent: PropTypes.string,
};

export default ReadingTimeCalculator;
