import React, { useEffect, useMemo, useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "@popperjs/core";
import "bootstrap";
import { Link, Outlet, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import Footer from '../footer/footer';
import Header from '../header/header';

function Home(){
  let mybutton ;
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [freeText, setFreeText] = useState('');
  const [freeSearchText, setFreeSearchText]= useState('');
  const navigate = useNavigate();
  function closeNavBar(){
    document.getElementById("myNavBar").click();
  }
  useEffect( ()=> {
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    if (window.location.pathname === "/") {
     // window.location.replace("/article")
    }
    if (localStorage.getItem("LANGUAGE")) {
    localStorage.getItem("LANGUAGE")
    }
    else {
      
      i18n.changeLanguage("ja");
      localStorage.setItem("LANGUAGE","ja")
    }
    setFreeSearchText('')
    setFreeText('')
    tableScroll();
    getCategoryList();
  },[window.location.pathname])
  useMemo(() => getCategory(categories), [categories]);
  function tableScroll(){
    mybutton = document.getElementById("myBtn");
    
  
    window.onscroll = function() {scrollFunction()};
  }

  
  function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  }

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  function getCategoryList(){
    axios.get(process.env.REACT_APP_BASE_URL + 'filtered/category/list').then(
      (response) => {
        console.log(response);
        setCategories(response.data.data);
        console.log(categories);
      },
      (error) => {
        
      }
    );
  }
  function getCategory(){
    setCategoryList(categories)
    console.log("categories",categories);
    console.log("catLIst",categoryList)
  }
  function articleSearch(e,row){
    e.preventDefault();
    document.getElementById("myNavBar").click();
    console.log(row);
    navigate("/category/"+row.id);

  }
  function search(e){
    e.preventDefault();
    searchByArticle();
  }
  const handleOnChangeFreeText = (event) => {
    setFreeText(event.target.value);
    console.log(freeText);
  };
  const handleKeyDown = (event) => {

    if (event.key === 'Enter') {
      event.preventDefault();
        searchByArticle();
    }
  }
  function searchByArticle(){
    let value = freeText.trim()
    // axios.get(process.env.REACT_APP_BASE_URL + 'articles?filters[title][$eq]='+ encodeURIComponent(freeText.trim())
    // .replace('_', '%5C%5F')
    // .replace(/\\/, '%5C')
    // .replace('%25',"%5C%25")).then(
    //   (response) => {
    //     debugger;
    //     setFreeSearchText('')
    //     setFreeText('')
    //     if(response.data.data.length !==0 && response.data.data[0].id !== undefined && response.data.data[0].id !== null){
    //       navigate("/search/" + response.data.data[0].id);
    //     }
      
    //   },
    //   (error) => {
        
    //   }
    // );
    if(freeText !==undefined && freeText !== null && freeText !== "" && freeText !== ''){
      setFreeSearchText('')
      setFreeText('')

      navigate("/search?key=" + encodeURIComponent(value.trim()));
    }
       
  }
  const handleOnChangeSearchFreeText = (event) => {
    setFreeSearchText(event.target.value);
    console.log(freeSearchText);
  };
  const handleSearchKeyDown= (event) => {
    
    if (event.key === 'Enter') {
      event.preventDefault();
      searchFreeWord(event);
    }
  }
  
  function searchFreeWord(e){
    document.getElementById("myNavBar").click();
    let value = freeSearchText.trim()
    // axios.get(process.env.REACT_APP_BASE_URL + 'articles?filters[title][$eq]='+ encodeURIComponent(freeSearchText.trim())
    // .replace('_', '%5C%5F')
    // .replace(/\\/, '%5C')
    // .replace('%25',"%5C%25")).then(
    //   (response) => {
    //     debugger;
    //     setFreeSearchText('')
    //     setFreeText('')
    //     if(response.data.data.length !==0 && response.data.data[0].id !== undefined && response.data.data[0].id !== null){
    //       navigate("/view/" + response.data.data[0].id);
    //     }
      
    //   },
    //   (error) => {
        
    //   }
    // );
    if(freeSearchText !==undefined && freeSearchText !== null && freeSearchText !== "" && freeSearchText !== ''){
    setFreeSearchText('')
    setFreeText('')
    navigate("/search?key=" + encodeURIComponent(value.trim()));
    }
  }
  return (
    <>
    <body className="d-flex flex-column h-100" id="page-top">

  <button onClick={topFunction} id="myBtn" title="Go to top" > <i className="fa-solid fa-arrow-up-long"></i></button>


  {/* <header>
    <nav className="navbar navbar-expand-lg navbar-light  main-header">
      <div className="container-fluid padding-body">
        <h5> <Link className='logo-header' to={"/"}> {t('top_heading')}</Link></h5>
        <form className="d-flex">
          <div className="input-group mt-3 mb-3 hide-search">
              <input type="text" className="form-control custom-search col-md-6" value={freeText} onChange={(e) => handleOnChangeFreeText(e)} 
                  onKeyDown={handleKeyDown} placeholder={t('search_article')} aria-label="Search" aria-describedby="basic-addon2" />
              <button type="button" className="input-group-text search-btn" id="basic-addon2" onClick={e=>search(e)}><i className="fa-solid fa-magnifying-glass"></i></button>
          </div>
          <button className="btn border-none" type="button" id="myNavBar" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
              <i className="fa-solid fa-bars fnt-25"></i>
          </button>
        </form>

        <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
          <div className="offcanvas-header justify-content-end">
            <button type="button" className="btn-close text-reset " data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            <div className="input-group mt-3 mb-3 px-4">
              <input type="text" className="form-control custom-search1" value={freeSearchText} onChange={(e) => handleOnChangeSearchFreeText(e)} 
                  onKeyDown={handleSearchKeyDown} placeholder={t('search_article')} aria-label="Search" aria-describedby="basic-addon2" />
              <button type="button" className="input-group-text search-btn" id="basic-addon2" onClick={e=>searchFreeWord(e)}><i className="fa-solid fa-magnifying-glass"></i></button>
          </div>


          <ul className="list-group list-group-flush mt-5 px-4">
          {categoryList.map((row, index) => (
           <> <li  onClick={e => articleSearch(e,row)} className="list-group-item py-3 list-custom d-flex w-100 justify-content-between"><span>{row.title}</span> <span><i className="fa-solid fa-angle-right"></i></span></li>
           </> ))} 
          </ul>
          </div>
        </div>
      </div>
    </nav>

  

    

  </header> */}

  <Header/>
  <div className="container-fluid p-0 mb-5">
  <Outlet></Outlet>
  </div>
  <Footer />
  </body>








  <script src="js/bootstrap.bundle.js"></script>
  </>

  )
}

export default Home;
