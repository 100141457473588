import React, { useEffect, useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "@popperjs/core";
import "bootstrap";
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import i18n from '../../i18n';
import '../header/header.css';

function Header() {
   const { t } = useTranslation();
   const [freeText, setFreeText] = useState('');
   const navigate = useNavigate();

   // Effect to set the language and initialize the component.
   useEffect(() => {
      const storedLanguage = localStorage.getItem('LANGUAGE');
      i18n.changeLanguage(storedLanguage || 'ja');
      if (!storedLanguage) {
         localStorage.setItem('LANGUAGE', 'ja');
      }
      setFreeText('')
   }, [])

   // Handle changes in the search input.
   const handleOnChangeFreeText = (event) => {
      setFreeText(event.target.value);
   };

   // Handle "Enter" key press in the search input.
   const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
         event.preventDefault();
         searchByArticle();
      }
   }

   // Perform a search when the user clicks the search button.
   const search = (e) => {
      e.preventDefault();
      searchByArticle();
   };

   // Function to navigate to the search page with the search query.
   const searchByArticle = () => {
      const value = freeText.trim();
      if (value) {
         // Clear the search input, then navigate to the search page with the query.
         setFreeText('');
         navigate(`/search?key=${encodeURIComponent(value)}`);
      }
   };

   // Render the header component.
   return (
      <header>
         <nav className="navbar navbar-expand-lg main-header">
            <div className="container-fluid padding-body">
               <h5>
                  {/* Link to the homepage with translated text */}
                  <Link className='logo-header' to={"/"}>
                     {t('top_heading')}
                  </Link>
               </h5>
               <ul className="nav nav-pills justify-content-center">
                  {/* <li className="nav-item headr-nav">
                     <a href="#" className="nav-link" aria-current="page">
                        article
                     </a>
                  </li>
                  <li className="nav-item headr-nav">
                     <a href="#" className="nav-link">
                        Useful materials
                     </a>
                  </li> */}
                  <li>
                     <div className="input-group mt-3 mb-3 ">
                        <input
                           type="text"
                           className="form-control search-headr"
                           value={freeText}
                           onChange={(e) => handleOnChangeFreeText(e)}
                           onKeyDown={handleKeyDown}
                           placeholder={t('search_article')}
                           aria-label="Search"
                           aria-describedby="basic-addon2"
                        />
                        <button
                           type="button"
                           className="input-group-text search-btn"
                           id="basic-addon2"
                           onClick={e => search(e)}
                        >
                           <i className="fa-solid fa-magnifying-glass"></i>
                        </button>
                     </div>
                  </li>
               </ul>
            </div>
         </nav>
      </header>
   )
}

export default Header;
