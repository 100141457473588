import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BannerWrapper } from './banner.styled';

// Banner component that displays an image based on the provided 'id'
const Banner = () => {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}banners`, {
          params: {
            populate: 'image',
            sort: 'updatedAt:desc',
          },
        }
        );
        const bannerData = response?.data?.data;
        setBanners(bannerData);
      } catch (error) {
        console.error('API call error:', error);
      }
    };
    // Call the fetchData function to initiate the API request
    fetchData();
  }, []);

  return (
    <>
      {banners.slice(0, 1).map((banner) => (
        <BannerWrapper key={banner?.id} data-testid="Banner">
          <picture>
            <img
              src={banner?.attributes?.image?.data?.attributes?.url}
              alt="bannerImage" />
          </picture>
        </BannerWrapper>
      ))}
    </>
  );
};

export default Banner;
