import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from "react-slick";
import { useNavigate } from 'react-router-dom';
import ReadingTimeCalculator from '../../../utils/read-time-calculator';
import clock from '../../../assets/svg/clock.svg';
import Image from '../../../assets/svg/Banner-image.svg';
import refresh from '../../../assets/svg/refresh.svg';
import formattedDate from '../../../utils/date';
import '../trending-slider/trending-slider.css';
// Slider Component
const TrendingSlider = () => {
  const navigate = useNavigate();
  const [trending, setTrending] = useState([]);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getTrendingList();
  }, [])

  const settings = {
    className: "center",
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    swipeToSlide: true,
    autoplaySpeed: 3000,
    lazyLoad: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  /**
   * Navigate to the detail view with the given id on click, preventing the default event behavior.
   * @param {*} e 
   * @param {*} id 
   */
  function viewDetail(e, id) {
    e.preventDefault();
    navigate("/view/" + id);
  }

  /**
   * Fetches the trending articles from the server and updates the state.
   */
  function getTrendingList() {
    axios.get(process.env.REACT_APP_BASE_URL + 'filtered/trending-article').then(
      (response) => {
        setTrending(response?.data?.data);
      },
      (error) => {
        setOpen(false);
        console.log(error)
      }
    );
  }

  return (
    <div className="my-4" style={{ overflow: 'hidden' }}>

      {trending?.length > 0 && (
        <Slider {...settings}>
          {trending.map((row) => (
            <div key={row?.article?.id}>
              <div className="trending-article-container">
                <img
                  loading='lazy'
                  className="trending-article"
                  src={row?.article?.image?.url !== undefined && row?.article?.image?.url !== null
                    ? row?.article?.image?.url
                    : Image
                  }
                  alt={row?.article?.title}
                  onClick={e => viewDetail(e, row?.article?.id)}
                />
              </div>
              <h6 className="mt-2" onClick={e => viewDetail(e, row?.article?.id)}>
                {row?.article?.title}
              </h6>
              <div className="text-end mt-2 gap-2">
                <div className="trending-article_card_date_time" style={{ display: 'inline-flex', marginLeft: 'auto' }}>
                  <img src={refresh} alt="Refresh Icon" />
                  {row?.article?.createdAt && formattedDate(row?.article?.createdAt)}
                </div>
                {row?.article?.content && (
                  <div className="ms-2 trending-article_card_date_time" style={{ display: 'inline-flex', marginLeft: 'auto' }}>
                    <img src={clock} alt="Clock Icon" />
                    <ReadingTimeCalculator bodyContent={row?.article?.content} />
                  </div>
                )}
              </div>
            </div>
          ))}
        </Slider>
      )}

    </div>
  );
};

export default TrendingSlider;