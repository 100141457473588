import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import moment from "moment";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw';
import remarkGfm from "remark-gfm";
import i18n from '../../i18n';
import Image from '../../assets/svg/Banner-image.svg'
import TrendingSlider from '../common/trending-slider/trending-slider';

moment.locale('ja', {
  relativeTime: {
    future: '%s in',
    past: '前 %s',
    s: '数秒',
    ss: '秒 %d',
    m: '数分',
    mm: '分 %d',
    h: '数時間',
    hh: '時間 %d',
    d: '数日',
    dd: "%d days",
    w: "a week",
    ww: "%d weeks",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years"
  },
});

function Article() {
  const params = useParams();
  const navigate = useNavigate();
  const [mainarticles, setMainArticles] = useState([]);
  const [open, setOpen] = React.useState(false);
  
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getArticleBySearch();
  }, [params.page])

  function getArticleBySearch() {
    axios.get(process.env.REACT_APP_BASE_URL + 'articles?[filters][category][id][$eq]=' + params.page + '&populate[0]=category&populate[1]=image&sort[0]=updatedAt%3Adesc').then(
      (response) => {
        setMainArticles(response?.data?.data);
        console.log(mainarticles, "main")
      },
      (error) => {
        console.log(error)
      }
    );
  }

  function viewDetail(e, id) {
    e.preventDefault();
    navigate("/view/" + id);
  }

  return (
    <>
      {/*slider area for trending articles starts*/}
      <TrendingSlider />
      {/*slider area for trending articles starts*/}

      <div className="clearfix"></div>

      <div className="container-fluid p-0">

        <section id="Company">
          <div className="padding-body">

            {mainarticles?.length !== 0 ? (<div className="mt-3 mb-5 d-flex justify-content-between">
              <div className="article-heading ">
                <h4>{mainarticles[0]?.attributes?.category?.data?.attributes?.title}</h4>
              </div>
            </div>) : (<></>)}


            <div className="row">

              {mainarticles.map((row, index) => {
                return (<>
                  {index === 0 || index === 1 || index === 2 || index === 3 ? <div className="col-md-3 col-article-3 table-view mb-2" key={index}>
                    <div className="card Card-blog  mb-3 card-table-view position-relative">
                      <div className="blog-whitebg">
                        <img src={row?.attributes?.image?.data?.attributes?.url !== undefined && row?.attributes?.image?.data?.attributes?.url !== null ? row?.attributes?.image?.data?.attributes?.url : Image} className="article-img-top" alt="..." />
                        <div className="blog-heading px-4 py-3">
                          <p className="read-more article-heading-text">{row?.attributes?.category?.data?.attributes?.title}</p>
                        </div>
                      </div>

                      <div className="card-body pb-4 p-leftt-article">
                        <div className="blog-head1 pb-4 select-point" onClick={e => viewDetail(e, row?.id)}><span className='category-heading-text'>{row?.attributes?.title}</span></div>
                        <p className="card-text  mb-5 category-description-text p-card">
                          {/* {row?.attributes?.content} */}
                          <ReactMarkdown children={row?.attributes?.content} className="react-markdown-test" remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} disallowedElements={"img ,table ,iframe"} />
                        </p>

                      </div>


                      <div className="footer-card p-leftt-article">

                        <div className="d-flex justify-content-between mt-3">
                          <p className="read-more pt-3">
                            {row?.attributes?.createdAt !== undefined && row?.attributes?.createdAt !== null ?
                              (<>{moment.utc(row?.attributes?.createdAt).format("DD/MM/YYYY hh:mm a")}</>) : (<></>)}

                          </p>
                          <span className='select-point' onClick={e => viewDetail(e, row?.id)}><i className="fa-solid fa-arrow-right-long ps-3 pt-4"></i></span>
                        </div>
                      </div>
                    </div>
                  </div> : <></>}
                </>)
              })}




            </div>


          </div>
        </section>

        <div className="clearfix"></div>

        <section id="Company" className="top-90">
          <div className="padding-body">

            <div className="row">
              {mainarticles.map((row, index) => {
                return (<>
                  {index !== 0 && index !== 1 && index !== 2 && index !== 3 ?
                    <div className="col-md-6">
                      <div className="row select-row">
                        <div className="col-md-4">
                          <div className="article-side"><img src={row?.attributes?.image?.data?.attributes?.url !== undefined && row?.attributes?.image?.data?.attributes?.url !== null ? row?.attributes?.image?.data?.attributes?.url : Image} alt="" /></div>
                        </div>
                        <div className="col-md-8">
                          <p className="read-more article-heading-text">{row?.attributes?.category?.data?.attributes?.title}</p>
                          <h5 className="py-2 pb-3 select-point" onClick={e => viewDetail(e, row?.id)}> <span className='category-heading-text'>{row?.attributes?.title}</span></h5>
                          <p className="card-text category-description-text p-card">
                            {/* {row?.attributes?.content} */}
                            <ReactMarkdown children={row?.attributes?.content} className="react-markdown-test" remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} disallowedElements={"img ,table ,iframe"} />
                          </p>
                          <p className="read-more pt-2">
                            {row?.attributes?.createdAt !== undefined && row?.attributes?.createdAt !== null ?
                              (<>{moment.utc(row?.attributes?.createdAt).format("DD/MM/YYYY hh:mm a")}</>) : (<></>)}

                          </p>

                        </div>
                      </div>
                      <hr />



                    </div> : <></>}
                </>)
              })}

            </div>


          </div>
        </section>

        <div className="clearfix"></div>



      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Article;
