import React from 'react';
import '../LPNav/LPNav.css';

const LPNav = () => {
    return (
        <>
            <div className="w-100 p-4 bg-white d-flex flex-column align-items-center custom-rounded">
                <div className="text-center">
                    <p className="text-black fs-5" style={{ fontWeight: 600 }}>
                        IoT/M2M向けSIMについてお問い合わせ
                    </p>
                    <p className="m-2 text-black" style={{ fontSize: '16px' }}>
                        IoT/M2M向けSIMについてご興味がある方はお問い合わせください。
                    </p>
                </div>
                <a
                    href="#"
                    style={{ background: '#ffda52', color: 'black', textDecoration: 'none', fontWeight: 600 }}
                    className="text-xl p-3 rounded-2 text-black fs-5 my-3"
                >
                    問い合わせする
                </a>
            </div>

        </>
    );
};

export default LPNav;
