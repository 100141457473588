import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import i18n from '../../i18n';
import moment from "moment";
import Pagination from '../../pagination';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Image from '../../assets/svg/Banner-image.svg'
import ReactMarkdown from 'react-markdown'
import remarkGfm from "remark-gfm";
import rehypeRaw from 'rehype-raw'
function ArticleSearch(){
  const [articleList, setArticleList] = useState([]);
  const { t } = useTranslation();
const params = useParams();
const search = useLocation().search;
const navigate = useNavigate();
const limit = 10;
const [count, setCount] = useState(0);
const [currentPage, setCurrentPage] = useState(1);
const [open, setOpen] = React.useState(false);
const [filter, setFilter] = React.useState(new URLSearchParams(search).get('key'));

  useEffect(() => {
    console.log("api",filter)
    setFilter(new URLSearchParams(search).get('key'))
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    getArticle(currentPage);

  },[new URLSearchParams(search).get('key')])
  function getArticle(page){
    setOpen(true);
   
    console.log("api",filter)
    // encodeURIComponent(params.page.trim())
    // .replace('_', '%5C%5F')
    // .replace(/\\/, '%5C')
    // .replace('%25',"%5C%25") 
    axios.get(process.env.REACT_APP_BASE_URL + 'filtered/article?search=' + 
     encodeURIComponent(new URLSearchParams(search).get('key').trim())
    .replace('_', '%5C%5F')
    .replace(/\\/, '%5C')
    .replace('%25',"%5C%25") +
    '&pageNo='+ page +'&pageSize='+limit).then(
      (response) => {
        console.log(response ,"dsd")
        setArticleList(response?.data?.data);
        
        setCount(response?.data?.totalCount);
        const totalPageCount = Math.ceil(response?.data?.totalCount / limit);
        console.log(totalPageCount)
        if(page > totalPageCount){
          setCurrentPage(totalPageCount);
          if(totalPageCount !== 0){
            console.log(currentPage);
            getArticle(totalPageCount);
          }
        }
        setTimeout(() => {
          setOpen(false);
        }, 500);
        setTimeout(() => {
          window.scrollTo({top: 0})
        }, 0);
      },
      (error) => {
        setOpen(false);
        console.log(error)
      }
    );
  }
  function pageChange(page) {
    console.log(page);
    setCurrentPage(page);
    getArticle(page);
  }
  function viewDetail(e,id){
    e.preventDefault();
    navigate("/view/" + id);
  }
  return (
    <>
        <section id="Company" class="top-90">
      <div class="padding-body">
        
          <div class="mt-5 mb-5 d-flex justify-content-start">
            <div class="article-heading ">
                <div class="mt-5 text-start">
                    <h6> <span>"</span><span>{filter}</span><span>"</span> {t('search_summary')}</h6>
                    {/* <p> sit amet consectetur adipisicing elit. Mollitia adipisci velit ad</p> */}
                 </div>
            </div>
        </div>
       
      </div>
    </section>

    <div class="clearfix"></div>
   
      <section id="Company" class="mt-4">
        <div class="padding-body">
    
        <div class="justify-content-center ">
            <div class="row mb-5">
                {/* <h6 class="mb-3"><b>Monday, February 13, 2023</b> </h6> */}
       {articleList.map((row,index) =>
            { return (
                  <>
                <div class="col-md-6">
                    <div class="row " >
                     <div class="col-md-4">
                       <div class="article-side"><img src={row?.image?.url !== undefined && row?.image?.url !== null ?
                       row?.image?.url : Image} alt="" /></div>
                     </div>
                     <div class="col-md-8">
                       <p class="read-more article-heading-text">{row?.category?.title}</p>
                       <h5 class="py-2 pb-5 select-point"  onClick={e => viewDetail(e,row?.id)}><span className='heading-text'>{row?.title}</span></h5>
                       <p class="card-text  article-description-text p-card">
                        {/* {row?.content} */}
                        <ReactMarkdown  children={row?.content} className="react-markdown-test" remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} disallowedElements={"img ,table ,iframe"} />
                        </p>
                       
                         <p class="read-more pt-2">
                         {row?.createdAt !== undefined && row?.createdAt !== null }
                          {moment.utc(row?.createdAt).format("DD/MM/YYYY hh:mm a")}
                          </p>
         
                     </div>
                    </div>
                    <hr />
    
                      
                   </div>
            
                   </>
) })}
           
    
            {articleList?.length === 0 ? (<div className='text-center'> {t('no_data')}</div>) :(<></>)} 
      
              </div>

              {/* <div class="row mb-5">
                <h6 class="mb-3"><b>Sunday, February 12, 2023</b> </h6>
                <div class="col-md-6">
                    <div class="row select-point">
                     <div class="col-md-4">
                       <div class="article-side"><img src="Images/article.png" alt="" /></div>
                     </div>
                     <div class="col-md-8">
                       <p class="read-more">Software/ React</p>
                       <h5 class="py-2">How Executive Headhunting is Different
                         from Entry-level Hunting</h5>
                       <p class="card-text ">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                         eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam</p>
                         <p class="read-more pt-2">12/12/2023 12:37PM</p>
         
                     </div>
                    </div>
                    <hr/>
    
                      
                   </div>
            
    
                   <div class="col-md-6">
                    <div class="row select-point">
                     <div class="col-md-4">
                       <div class="article-side"><img src="Images/article.png" alt="" /></div>
                     </div>
                     <div class="col-md-8">
                       <p class="read-more">Software/ React</p>
                       <h5 class="py-2">How Executive Headhunting is Different
                         from Entry-level Hunting</h5>
                       <p class="card-text ">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                         eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam</p>
                         <p class="read-more pt-2">12/12/2023 12:37PM</p>
         
                     </div>
                    </div>
                    <hr />
    
                      
                   </div>
    
                   <div class="col-md-6">
                    <div class="row select-point">
                     <div class="col-md-4">
                       <div class="article-side"><img src="Images/article.png" alt="" /></div>
                     </div>
                     <div class="col-md-8">
                       <p class="read-more">Software/ React</p>
                       <h5 class="py-2">How Executive Headhunting is Different
                         from Entry-level Hunting</h5>
                       <p class="card-text ">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                         eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam</p>
                         <p class="read-more pt-2">12/12/2023 12:37PM</p>
         
                     </div>
                    </div>
                    <hr />
    
                      
                   </div>
    
    
                   <div class="col-md-6">
                    <div class="row select-point">
                     <div class="col-md-4">
                       <div class="article-side"><img src="Images/article.png" alt="" /></div>
                     </div>
                     <div class="col-md-8">
                       <p class="read-more">Software/ React</p>
                       <h5 class="py-2">How Executive Headhunting is Different
                         from Entry-level Hunting</h5>
                       <p class="card-text ">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                         eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam</p>
                         <p class="read-more pt-2">12/12/2023 12:37PM</p>
         
                     </div>
                    </div>
                    <hr />
    
                      
                   </div>
    
    
           
    
             
      
              </div> */}


              
        </div>

        <div class="col-md-12 my-5">
            {/* <nav aria-label="Page navigation example">
                <ul class="pagination justify-content-end">
                  <li class="page-item  page-list">
                    <a class="page-link page-btn" href="#" tabindex="-1" aria-disabled="true"><i class="fa-solid fa-chevron-left"></i></a>
                  </li>
                  <li class="page-item page-list"><a class="page-link page-num" href="#">1</a></li>
                  <li class="page-item page-list"><a class="page-link page-num" href="#">2</a></li>
                  <li class="page-item page-list"><a class="page-link page-num" href="#">3</a></li>
                  <li class="page-item page-list"><a class="page-link page-num" href="#">4</a></li>
                  <li class="page-item page-list"><a class="page-link page-num" href="#">5</a></li>
                  <li class="page-item page-list">
                    <a class="page-link page-btn" href="#"><i class="fa-solid fa-chevron-right"></i></a>
                  </li>
                </ul>
              </nav> */}
                <Pagination
            className="pagination-bar page-item page-list"
            currentPage={currentPage}
            totalCount={count}
            pageSize={limit}
            onPageChange={(page) => pageChange(page)}
          />
        </div>
  
       
        </div>
      </section>
  
      <div class="clearfix"></div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )

}

export default ArticleSearch;
