import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import i18n from '../../i18n';
import refresh from '../../assets/svg/refresh.svg';
import clock from '../../assets/svg/clock.svg';
import formattedDate from '../../utils/date';
import ReadingTimeCalculator from '../../utils/read-time-calculator';
import Banner from '../common/banner/banner';
import TrendingSlider from '../common/trending-slider/trending-slider';
import BigBanner from '../big-banner/big-banner';
import  '../article-list/article-list.css'
function ArticleList() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [article, setArticle] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    getArticle();
    getCategoryList();
  }, [])

  /**
   * Fetches articles, and updates state
   */
  function getArticle() {
    setOpen(true);
    axios.get(process.env.REACT_APP_BASE_URL + 'filtered/category/articles').then(
      (response) => {
        setArticle(response?.data?.data);
        setOpen(false);
      },
      (error) => {
        setOpen(false);
        console.log(error)
      }
    );
  }

  /**
   * Navigate to the detail view with the given id on click, preventing the default event behavior.
   * @param {*} e 
   * @param {*} id 
   */
  function viewDetail(e, id) {
    e.preventDefault();
    navigate("/view/" + id);
  }

  function getArticlesByCategoryId(e, data) {
    e.preventDefault();
    navigate("/category/" + data);
  }

  function getCategoryList() {
    axios.get(process.env.REACT_APP_BASE_URL + 'filtered/category/list').then(
      (response) => {
        setCategoryList(response.data.data);
      },
      (error) => {
        console.log('API call error:', error);
      }
    );
  }

  return (
    <>
      {/*slider area for trending articles starts*/}
      <TrendingSlider />
      {/*slider area for trending articles ends*/}

      {/* card area starts*/}
      <div className="container mb-3 mt-4">
        <div className="row">
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-12">
                <div className="blog_title">おすすめ記事</div>
              </div>
            </div>

            {/* card area for first category starts*/}
            {article?.length > 0 && article.slice(0, 1).map((row) => {
              return (
                <div key={row?.id} >
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <div className="d-flex">
                        <div>{row?.title}</div>
                        <div className="flex-grow-1">
                          <hr className="ms-2" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {row?.articles?.length > 0 && row?.articles.map((article) => {
                      return (
                        <div key={article?.id} className="col-md-6 col-lg-4 mb-3">
                          <div className="card-body bg-white card_size p-3">
                            <div className="card-image-container">
                              <img
                                alt={`Card ${article?.id} Image`}
                                loading='lazy'
                                src={article?.image?.url}
                                className="card_image"
                                onClick={e => viewDetail(e, article?.id)}
                              />
                            </div>
                            <h6 className="mt-4"
                              onClick={e => viewDetail(e, article?.id)}>{article?.title}</h6>
                            <div className="card_text"> {article?.introductory_sentence}</div>
                            <div className="d-flex mt-2">
                              <div className="card_date_time">
                                <img src={refresh} alt='refresh-icon' /> {article?.createdAt && formattedDate(article?.createdAt)}
                              </div>
                              {article?.content && (
                                <div className="ms-2 card_date_time">
                                  <img src={clock} alt="clock icon" />
                                  <ReadingTimeCalculator bodyContent={article?.content} />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>

                  <div className="row">
                    <div className="col-md-12 text-center">
                      <button type="button" className="btn dark-blue-button" onClick={e => getArticlesByCategoryId(e, row?.id)}>{t('view_article_category')}</button>
                    </div>
                  </div>
                </div>
              )
            })}
            {/* card area for first category ends*/}

            {/* card area for second category starts*/}
            {article?.length > 0 && article.slice(1, 2).map((row) => {
              return (
                <div key={row?.id}>
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <div className="d-flex">
                        <div>{row?.title}</div>
                        <div className="flex-grow-1">
                          <hr className="ms-2" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {row?.articles?.length > 0 && row?.articles.slice(0, 3).map((article) => {
                      return (
                        <div key={article?.id} className="col-md-6 col-lg-4 mb-3">
                          <div className="card-body bg-white card_size p-3">
                            <div className="card-image-container">
                              <img
                                alt="card-image"
                                loading='lazy'
                                src={article?.image?.url}
                                className="card_image"
                                onClick={e => viewDetail(e, article?.id)}
                              />
                            </div>
                            <h6 className="mt-4 "
                              onClick={e => viewDetail(e, article?.id)}>{article?.title}</h6>
                            <div className="card_text"> {article?.introductory_sentence}</div>
                            <div className="d-flex mt-2">
                              <div className="card_date_time">
                                <img src={refresh} /> {article?.createdAt && formattedDate(article?.createdAt)}
                              </div>
                              {article?.content && (
                                <div className="ms-2 card_date_time">
                                  <img src={clock} alt="clock icon" />
                                  <ReadingTimeCalculator bodyContent={article?.content} />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>

                  <div className="row ">
                    <div className="col-md-12 text-center">
                      <button type="button" className="btn dark-blue-button" onClick={e => getArticlesByCategoryId(e, row?.id)}>{t('view_article_category')}</button>
                    </div>
                  </div>
                </div>
              )
            })}
            {/* card area for second category ends*/}

          </div>

          <div className="col-md-3 mt-5">

            {/* side banner area starts */}
            <div className="banner-container">
              <div className="banner-image">
                <Banner />
              </div>
            </div>
            {/* side banner area ends */}

            {/* category area starts */}
            <div className="row my-3">

              <div className="mb-3">
                <h4 className="blog_list_h4" >{t('category')}</h4>
                <div className="border-bottom-article"></div>
              </div>

              <ul
                className="list-group list-group-flush px-4">
                {categoryList.map((row) => (
                  <>
                    <li key={row?.id}
                      onClick={e => getArticlesByCategoryId(e, row?.id)}
                      className="list-group-item py-3 list-custom d-flex w-100 justify-content-between">
                      <span>{row?.title}</span>
                      <span>
                        <i className="fa-solid fa-angle-right"></i>
                      </span>
                    </li>
                  </>
                ))}
              </ul>

            </div>
            {/* category area ends */}
          </div>
        </div>
      </div >
      {/* card area ends*/}

      {/* big-banner area start */}
      <div className="col-12 mb-3">
        <div>
          <BigBanner />
        </div>
      </div>
      {/* big-banner area end */}

      {/* card area starts*/}
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            {/* card area for third category starts*/}
            {article?.length > 0 && article.slice(2).map((row) => {
              return (
                <>
                  <div key={row?.id} className="row mt-4">
                    <div className="col-md-12">
                      <div className="d-flex">
                        <div>{row?.title}</div>
                        <div className="flex-grow-1">
                          <hr className="ms-2" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {row?.articles?.length > 0 && row?.articles.slice(0, 3).map((article) => {
                      return (
                        <div key={article?.id} className="col-md-6 col-lg-4 mb-3">
                          <div className="card-body bg-white card_size p-3">
                            <div className="card-image-container">
                              <img
                                alt="card-image"
                                loading='lazy'
                                src={article?.image?.url}
                                className="card_image"
                                onClick={e => viewDetail(e, article?.id)}
                              />
                            </div>
                            <h6 className="mt-4 "
                              onClick={e => viewDetail(e, article?.id)}>{article?.title}</h6>
                            <div className="card_text"> {article?.introductory_sentence}</div>
                            <div className="d-flex mt-2">
                              <div className="card_date_time">
                                <img src={refresh} /> {article?.createdAt && formattedDate(article?.createdAt)}
                              </div>
                              {article?.content && (
                                <div className="ms-2 card_date_time">
                                  <img src={clock} alt="clock icon" />
                                  <ReadingTimeCalculator bodyContent={article?.content} />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>

                  <div className="row ">
                    <div className="col-md-12 text-center">
                      <button type="button" className="btn dark-blue-button" onClick={e => getArticlesByCategoryId(e, row?.id)}>{t('view_article_category')}</button>
                    </div>
                  </div>
                </>
              )
            })}
            {/* card area for third category ends*/}

          </div>
          <div className="col-md-3"> </div>
        </div>
      </div >
      {/* card area ends*/}

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default ArticleList;
